.to-right {
  text-align: right;
}

.resume {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.resume .item {
  text-align: center;
  flex: 1;
}

.resume .item > .subtitle {
  font-weight: 600;
}

.initial-value {
  margin-top: 10px;
  column-gap: 10px;
  display: flex;
  justify-content: flex-end;
}

.cash-register-statement ul {
  display: grid;
  gap: 10px;
}

.cash-register-statement li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
