.to-right {
  text-align: right;
}

.resume {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.resume .item {
  text-align: center;
}

.resume .item > .subtitle {
  font-weight: 600;
}

.additional {
  margin-top: 20px;
  row-gap: 10px;
  display: grid;
}

.additional .item,
.environment .item,
.general .item,
.deliverers .deliverer-item .deliverer-item__item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #000;
}

.products,
.environment,
.general,
.payment-methods {
  margin-top: 20px;
}

.deliverers {
  margin-top: 20px;
  display: grid;
  gap: 20px;
}

.deliverers .deliverer-item {
  display: grid;
}

.deliverers .subtitle,
.payment-methods .subtitle,
.environment .subtitle,
.general .subtitle,
.products .subtitle {
  font-weight: 600;
  margin-bottom: 10px;
}

.products .table-header {
  display: grid;
  grid-template-columns: 1fr 30px 80px;
  font-weight: 400;
}

.products .product-item {
  display: grid;
  grid-template-columns: 30px 1fr 80px;
  border-bottom: 1px dashed #000;
}

.payment-methods .table-header {
  display: grid;
  grid-template-columns: 1fr 80px;
  font-weight: 400;
}

.payment-methods .product-item {
  display: grid;
  grid-template-columns: 1fr 80px;
  border-bottom: 1px dashed #000;
}
